import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import "../style/main.scss"
const Subscribed = () => {
  const data = useStaticQuery(graphql`
    query {
      contactImage: file(relativePath: { eq: "brassens_white.png" }) {
        childImageSharp {
          fluid(maxHeight: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <section className="success">
      <div className="success__content">
        <div className="success__content__logo">
          <Img fluid={data.contactImage.childImageSharp.fluid} />
        </div>
        <h2>Merci pour votre abonnement !</h2>
        {/* <p>
          Vous allez recevoir prochainement un email de confirmation&nbsp;:
          merci de cliquer sur le lien pour confirmer votre inscription.
        </p> */}
        <p>Vous recevrez prochainement de mes nouvelles, à bientôt&nbsp;!</p>
        <Link to="/">
          <div className="btn-back success__content__arrow">
            <span className="btn-back__arrow"></span>
          </div>
        </Link>
      </div>
    </section>
  )
}

export default Subscribed
